<template>
  <a
    :href="link"
    class="pr-3 pl-3 sm:pr-0 sm:pl-4"
    :target="!isLocal && `_blank` || undefined"
    :rel="!isLocal && `noopener noreferrer` || undefined"
  >
    <i
      :class="`text-2xl bx ${icon}`"
    />
  </a>
</template>

<script setup lang="ts">
const props = defineProps({
  link: {
    type: String,
    default: '#',
  },
  icon: {
    type: String,
    default: 'bx-ghost',
  },
})

const isLocal = computed(() => {
  return props.link.startsWith('#') || props.link.startsWith('/')
})
</script>

<!-- bxl-linkedin -->
<!-- https://www.linkedin.com/in/daniel-b-hagen -->
